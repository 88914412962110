.c-error-page {
    width: 400px;
    display: flex;
    font-family: markPro;
    max-width: 90vw;
    .p-dialog-header{
        padding: 0!important;
        .p-dialog-header-icons{
            position: absolute;
            right: 1rem;
            top: 1rem;
            span{
                color: #F59800;
            }
        }
        img {
            width: 100%;
        }
    }
    img{
        width: 120px;
        margin: auto;
    }
}
.c-stadfesta {
    z-index: 9999!important;
}
.c-master-err-dialog{
    width: 95vw;
    max-width: 400px;
}
