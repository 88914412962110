.c-giftcard-container{
    min-height: 140px;
    .c-giftcard-form{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        width: 100%;
        min-height: 140px;
        input {
        }
        p {
            text-align: left;
        }
        .p-button{
            font-family: sans-serif!important;
            margin-right: 0;
        }
    }
}
