/* At the top level of your code */
@media screen and (min-width: 900px) {
  .user-info .podcast-details {
    min-width: 440px;
  }
}
.c-blog-demo{
  zoom: 50%;
}
.p-inputnumber-buttons-stacked .p-inputnumber-input {
  width: 3rem!important;
}
/*body:has(.c-episode-box) #root {
  max-height: calc(100vh - 110px);
  position: absolute;
  width: 100%;
  overflow: auto;
}*/
.p-fileupload .p-fileupload-content{
  padding: 0!important;
}
.c-episode-container {
  margin: auto;
  .c-button-container{
    // position: fixed;
    bottom: 0;
    right: 0;
    background: black;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    div{
      margin: 2rem;
    }
  }
  .c-episode-box{
    display: flex;
    /* margin: auto; */
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: flex-start;
    color: black;
    background: white;
    font-family: sans-serif;
    .c-episode-example{
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 95vw;
      width: 301px;  
    }
    .c-episode-files{
      min-width: 30%;
    }
    .c-episode-form{
      max-width: 25%;
    }
    .c-episode-box-div{
      height: 100%;
      .c-episode-box-dropdown{
        flex-direction: column;
        align-items: center;
      }
    }
    .c-empty-span{
      font-size: 1em;
      color: var(--text-color-secondary);
    }
    .c-empty-i{
      font-size: 1.5em;
      border-radius: 50%;
      background-color: var(--surface-b);
      color: var(--surface-d);
    }
    .c-playlist-item {
      width: 21em;
    }
    .playlist-item {
      width: 21em;
    }
    .c-episode-form{
      font-family: sans-serif;
      color: black;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 95vw;
      width: 300px;
      div {
        text-align: left;
      }
      .c-episode-length{
        display: flex;
        flex-direction: column;
        .p-inputnumber {
          margin: 0.5rem;
        }
      }
      .c-giftcard-submit {
        button {
          margin-top: 2rem;
        }
      }
    }
  }
}
.sd-userInfo{
  padding: 1rem;
}
@media screen and (max-width: 960px) {
  .c-user-box{
    border-radius: 0px!important;
  }
}