.c-sd-cookies-container{
    width: 100%;
    background: var(--steve-orange-main);
}
.c-sd-cookies{
    max-width: 1080px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: var(--steve-base);
    font-family: sans-serif;
}
.c-sd-cookie-info{
    height: 100%;
    max-width: 100vw;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p{
        text-align: left;
    }
    .c-cookies-back{
        width: 100%;
        margin: 2rem;
    }
}
.c-cookie-data{
    max-width: 90vw!important;
}
