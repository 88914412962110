
@import '../../config.scss';
Img{
    max-width: 100%;
}
.c-home.podcast-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
    align-items: flex-start;
    /*background-color: #efefef;*/
    background-color: #313131;
}
.c-home .info-container{
    display: flex;
    align-items: center;
    width: 100vw;
    flex-basis: content;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #8b0062;
    color: #F2F2F2;
    padding: 0 0 1rem 0;
}

.c-home {
    .c-hlusta-category{
        max-width: 100%;
        background-color: #313131;
        color: white;
        .episodes-container{
            margin: 0 1rem;
            display: flex;
            max-width: 100vw;
            -webkit-overflow-scrolling: touch;
            /*overflow-x: scroll;
            scroll-snap-type: x mandatory;*/
        }
        .episodes-container::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
            background-color: transparent;
        }
        .episodes-container::-webkit-scrollbar-thumb
        {
            background-color: #d6dee1;
            border-radius: 20px;
            border: 6px solid transparent;
            background-clip: content-box;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #F59800;
            &:hover{
                background-color: #a8bbbf;
            }
        }
        .episodes-container::-webkit-scrollbar
        {
            width: 12px;
            background-color: transparent;
        }

        .c-playlist-item{
            scroll-snap-align: center;
            margin: 0.5rem 1rem;
            width: 21em!important;
        }
        .playlist-item{
            width: 21em!important;
        }
        .p-blockui.p-component-overlay {
            z-index: 985 !important;
        }
        .c-hm-season{
            .p-card-header img {
                /* width: 350px; */
                height: auto!important;
                object-fit: contain!important;
            }
        }
        .c-sd-header{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 1rem 0.5rem 1rem;
            max-height: $--hlusta-header-height;
            .p-button-icon-only{
                color: var(--steve-orange-main)!important;
                &:hover{
                    background: var(--steve-orange-main)!important;
                    color: var(--steve-black-text)!important;
                }
                &:focus{
                    background: var(--steve-orange-main)!important;
                    color: var(--steve-black-text)!important;
                }
            }
        }
        .c-sd-text{
            text-align: start;
            color: white;
            margin-left: 1rem;
            font-size: 2rem;
        }
    }
}
.c-home .subContainer-flex{
    max-width: 80%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: auto;
}
.c-sub-btn {
    margin-bottom: 1rem;
}
.c-home .react-jinke-music-player-mobile-cover .c-home .cover {
    animation: none;
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.p-divider .p-divider-content {
    /*background-color: #efefef;*/
    color: #ffffff;
    background-color: var(--steve-black-base);
}

.button-container .social-container{
    display: flex;
    align-items: center;
}
.button-container .social-container span{
    font-size: 1rem;
    margin-right: 1rem;
}
.button-container .social-container i{
    color: #A60A8C;
}
.button-container .social-container i:hover{
    color: #fff;
}
.episodes-loadmore{
    width: 100%;
    margin: 2rem 0 4rem 0;
    max-width: 90vw;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}
.episodes-loadmore button{
    background: #A60A8C !important;
    border-color: #A60A8C !important;
}
.episodes-loadmore button:hover{
    background: #A60A8C !important;
    border-color: #A60A8C !important;
}
/*.episodes-loadmore .p-button:focus {
    box-shadow: 0 0 0 0.2rem rgb(166, 10, 140/ 50%);
}*/

@media screen and (max-width: 820px) {
    .c-home .c-hlusta-category .episodes-container {
    }
}