.c-payment-container {
    Img{
        max-width: 100%;
    }
    /* ToastDemo.css */
    .p-toast{
        max-width: 97%;
    }
    @media screen and (max-width: 960px) {
        .c-fullw{
            &button {
                width: 100%;
                margin-bottom: .5rem;
            }
        }
    }

    .loading-spinner{
        position:fixed;
        left: calc(50% - 50px);
        top: 35%;
        z-index: 9999;
    }
    .users-cc{
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
    }
    .users-cc .users-cc-info{
        display: flex;
    }
    .users-cc ul {
        margin: 0;
        padding: 0;
    }
    .users-cc ul li {
        display: flex;
        justify-items: center;
        align-items: center;
        list-style-type: none;
        padding-left: 0;
        border-style: solid;
        margin: 1rem;
        padding: 1rem;
        border-width: 0px;
        border-radius: 5px;
        border-width: 2px;
        border-color: transparent;
    }
    .users-cc ul li:hover {
        border-color: grey;
    }
    .users-cc ul li.activeCC{
        border-color: blue;
    }

    .users-cc img{
        height: 45px;
    }
    .subContainer-flex{
        max-width: 80%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        margin: auto;
    }
    .flex-center-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 1000px;
        min-height: 100%;
        max-width: 100vw;
        margin: auto;
        justify-content: flex-start;
        align-items: stretch;
        border: 1px solid;
        border-color: #efefef;
        background: var(--surface-card);
        align-content: stretch;
    }
    .p-divider .p-divider-content {
        /* background-color: #efefef; */
        background-color: #ffffff;
        color: var(--steve-black-base);
    }
    .steps-stretch{
        width: 100%;
    }
    .payment-form{
        display: flex;
        max-width: 100vw;
        flex-wrap: wrap;
        font-family: sans-serif;
    }
    .payment-form .p-divider .p-divider-content{
        background-color: var(--surface-card);
    }
    .payment-methods-cards, .card-inputs{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .payment-options{
        width: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
        justify-content: flex-end;
    }
    .payment-submit{
        margin-top: 1.2rem;
    }
    .product-image{
        width: 45px;
    }
    /* Data table útlit*/
    .c-cards-table-data{
        border-style: solid;
        border-width: 1px;
        padding: 1rem;
        border-radius: 1rem;
        padding: 1rem 0 1rem 0;
        background-color: #f8f9fa;
        border-color: #efefef;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .p-datatable .p-datatable-thead > tr > th{
        border: none !important;
    }
    .c-cards-table-header{
        color: yellow;
    }
    .c-cards-table{
        margin-bottom: 1rem;
    }
    /* Reiknings upplýsingar */
    /*.c-cards-receipt{
        border-style: solid;
        border-width: 1px;
        padding: 1rem;
        border-radius: 1rem;
        border-color: #efefef;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin-bottom: 1rem;
    }*/
    .c-user-info{
        font-family: sans-serif;
      }
      .c-user-info-val button {
        margin: 1.5rem;
        width: 100%;
      }
      .c-user-info-val-disp{
        font-size: 1.2rem;
        margin-right: 1rem;
      }
      .c-user-info-val-val{
        margin-left: auto;
        .sd-exclude{
            margin-left: 0;
        }
        .sd-retry{
            margin: 0;
            padding:5px;
        }
      }
      .c-user-info-val-warn{
        color: rgb(220, 53, 69);
      }
      .c-user-info-val{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        height: 2rem;
      }
      .p-datatable .p-datatable-footer {
        border: none!important;
        padding: 0px!important;
    }

    .c-card-container{
        max-width: 100vw;
        margin: 1rem;
        flex-grow: 1;
        flex: 3;
        min-width: 250px;
        .c-cards-table{
            .p-datatable .p-datatable-tbody > tr.p-highlight:has(.status-error) {
                color: red;
                .p-focus{
                    box-shadow: 0 0 0 0.2rem #d70f0f;
                }
                .p-radiobutton-box.p-highlight {
                    border-color: red;
                    background: red;
                }
            }
            .customer-badge{
                padding: 5px;
                &.status-success{

                }
                &.status-error{
                    background-color: red;
                    color: var(--steve-white-text);
                }
            }
        }
    }
    .c-subscription-form{
        max-width: calc(100vw - 2rem);
        margin: 1rem;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        flex: 2;
    }
    .c-user-sub-cncl{
        margin: 1.5rem 0 1rem 0;
    }
    .c-info-danger{
        color: #EF4444
    }
    .p-datatable .p-datatable-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .c-master-err-dialog{
        width: 95vw;
        max-width: 800px;
    }

}