@import '../../config.scss';
.c-hlusta-category{
    width: 800px;
    max-width: 100vw;
    ul {
        margin: 0;
        padding: 0;
    }
}
.c-skeleton-margin{
    margin: 1rem;
}
.c-playlist-itemv2{
    cursor: pointer;
    .c-playlist-info {
        border-bottom: 1px solid #f5980096; // 1px solid var(--steve-black-text);
        /* border-radius: 1rem; */
        margin-top: 1rem;
        max-width: 95vw;
        min-width: 240px;
        color: white;
        font-family: sans-serif;
        display: flex;
        flex-direction: column;
        .p-button:focus {
            box-shadow: none!important;
        }
        .p-chip{
            &:hover{
                cursor: pointer;
            }
        }
        .c-add-track-btn{
            position: absolute;
            right: 0;
        }
        .p-card-header{
            border-radius: 1rem 1rem 0 0;
            .p-skeleton{
                border-radius: 1rem 1rem 0 0;
            }
        }
    }
    .c-playlist-info{

        .c-playlist-img {
            display: flex;
            flex-direction: row;
            align-items: center;
            img{
                width: 56px;
                margin: 1rem;
            }
        }
        .c-episode-controls{
            display: flex;
            i {
                color: #31c27c;
            }
            .p-button.p-button-text.p-button-plain {
                color: white;
            }
            .p-button.p-button-text.p-button-plain:enabled:hover, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):hover {
                background: #313131;
                color: #6c757d;
            }
            .p-button.p-button-text.p-button-plain:enabled:active, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):active {
                background: #313131;
                color: #6c757d;
            }
        }
        .c-playlist-title{
            font-weight: 600;
            font-size: 1.2rem;
        }
        .p-blockui-container{
            margin-top: 0;
            top: -1rem;
        }
        .p-blockui.p-component-overlay {
            z-index: 985 !important;
        }
    }
    .c-playlist-info ul {
        list-style: none;
    }
    .c-playlist-info div{
        overflow: hidden;
    }
    .progress {
        width: 1px;
        height: 3px;
        background: #006B5A;
        transition: width .1s linear;
    }
    .c-episode-icons {
        display: flex;
        width: 100%;
    }
    .c-episode-icons-container {
        align-self: center;
        margin-right: 1rem;
    }
    .c-episode-info-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 0.5rem;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .c-episode-info-text::-webkit-scrollbar-track {
        background-color: transparent;
    }
    .c-episode-info-text::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
        &:hover{
            background-color: #a8bbbf;
        }
    }
    .c-episode-info-text p{
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow-y: scroll;
        overflow-x: hidden;
        text-align: start;
    }
        /* Hide scrollbar for IE, Edge and Firefox */
        p::-webkit-scrollbar-track {
                /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
                border-radius: 10px;
                background-color: transparent;
        }
        p::-webkit-scrollbar-thumb {
                border-radius: 10px;
                /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
                background-color: grey;
        }
        p::-webkit-scrollbar{
            width: 3px;
            background-color: transparent;
        }
    .c-episode-info-text .c-episode-tags a{
        color: #0D0D0D;
        text-decoration: none;
    }
    .c-episode-info-text .c-episode-tags{
        margin-top: 4px;
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }
    p .p-skeleton{
        margin-top: 0.5em;
    }
    .c-episode-info-text .c-episode-tags .pi-tag{
        margin-right: 0.5rem;
    }
    .p-card .p-card-title{
        box-shadow: none;
        margin: 0;
    }
    .c-episode-icons-container span{
        padding-right: 0.25rem;
    }
    .p-progressbar .p-progressbar-value {
        background: var(--steve-orange-main)!important;
    }
    .p-chip:has(.c-episode-tag-active){
        background-color: var(--steve-orange-main);
    }
}

/* @media screen and (max-width: 820px) {
    .c-hlusta{
        .episodes-container{
            overflow: auto;
            scroll-snap-type: y mandatory;
            max-height: calc(100vh - #{$header_height});
        }
        .c-c-playlist-info{
            scroll-snap-align: start;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .c-playlist-info{
            width: 21em!important;
        }
        .p-blockui.p-component-overlay {
            border-radius: 1rem;
            margin-top: 1rem;
            height: calc(100% - 1rem);
            z-index: 985 !important;
        }
    }
}
*/
@media screen and (min-width: 821px) {
}
