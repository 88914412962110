@import "../../config.scss";
header{
  flex: none;
}

.p-header-menu{
  &.p-menu:not(.sd-exclude) {
    border-radius: 0px!important;
    ul li{
      background-color: var(--steve-orange-main);
    }
    .p-submenu-header{
      background: #{$--steve-orange-main}!important;
    }
    &.p-menu-overlay {
      top: #{$header_height}!important;
      right: 0!important;
      left: auto!important;
      width: 100vw!important;
      max-width: 30rem!important;
      background: #{$--steve-orange-main}!important;
      li{
        font-family: $font-main;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        color: $--steve-black-text;
      }
      .p-menuitem-link:not(.p-disabled){
        .p-menuitem-text, .p-menuitem-icon {
          color: $--steve-black-text;
        }
      }
       .p-menuitem-link:not(.p-disabled):hover {
        background: $--steve-orange-secondary;
      }
    }
  }
}

.sticky-header{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.p-menubar{
  background-color: var(--steve-orange-main);
  border-radius: 0px;
  border: 1px solid var(--steve-orange-secondary)!important;
  height: $header_height;
}
.p-menubar .header-site-title {
  position: relative;
  margin-left: 12px;
  font-size: 1.8rem;
  color: #1A1A1A
}
.p-menubar .heading-image,
.p-menubar .heading-image a{
  display: block;
  text-decoration: none;
}
.p-menubar a{
  width: 100%;
  height: 100%;
}
.p-menubar .p-menubar-button{
  display: none;
}
.p-button.c-sd-black:focus{
  box-shadow: 0 0 0 1px #1a1a1a!important;
}
.c-sd-black{
  color: var(--steve-btn-black)!important;
}
.heading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-repeat: no-repeat; */
  /*margin-bottom: $gutter * 2;*/

  &--main {
    /*background-image: url("~/img/header.jpg");*/
  }

  &__category {
    position: relative;
    z-index: 1;
    display: block;

    font-family: $font-heading;
    font-size: 1rem;
    text-transform: uppercase;
  }

  &__title {
    position: relative;
    z-index: 1;
    font-family: $font-heading;
    font-size: 3rem;
  }
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 20px;
  right: 20px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  top: 17px;
  right : 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}
.nav-container input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav-container input[type="checkbox"]:checked ~ .logo{
  display: none;
}
.c-sd-hamburger{
  height: 76px;
  width: 76px;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 0px;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: #DE8D08;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .p-menubar{
    .p-menubar-end{
      .c-sd-login-btn{
        height: 76px;
        width: 76px;
        position: absolute;
        top: 0px;
        right: 0px;
        border-radius: 0px;
        border-width: 0 0 0 1px;
        border-color: #DE8D08;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:hover {
          border: none!important;
        }
        &:focus {
          border: none!important;
        }
        .p-button-label{
          font-size: 11px;
          flex: 0;
        }
        .p-button-icon-left{
          margin: 0 auto!important;
        }
      }
    }
  }
}
