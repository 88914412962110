$sd_input_background: #000000;
$sd_input_border: #444444;
$sd_input_text: #BBBBBB;
$sd_text: #FFFFFF;
.sd-dark-theme{
    background-color: var(--steve-black-base)!important;
    .c-sd-nyskra{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .p-divider-content{
        background-color: var(--steve-black-base)!important;
    }
    .text-900:not(.p-error), .text-600:not(.p-error), .p-divider, label:not(.p-error){
        color: $sd_text!important;
    }
    .p-divider-content{
        color: $sd_input_text;
    }
    .sd-google-login{
        display: block;
    }
    &.shadow-2{
        box-shadow: none!important;
    }
    .p-inputtext {
        background: $sd_input_background;
        color: $sd_input_text;
        border: 1px solid $sd_input_border;
        &:hover{
            border-color: $sd_input_text;
        }
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover, .p-checkbox .p-checkbox-box.p-highlight {
        border-color: var(--steve-orange-main);
        background: var(--steve-orange-main);
    }
    .p-checkbox.p-invalid > .p-checkbox-box {
        border-color: var(--steve-orange-secondary);
    }
    .p-checkbox .p-checkbox-box {
        border: 2px solid $sd_input_text;
        background: $sd_input_background;
        .p-checkbox-icon {
            color: $sd_input_background;
        }
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        box-shadow: 0 0 0 0.2rem var(--steve-orange-secondary)!important;
        border-color: var(--steve-orange-secondary)!important;
    }
    .sd-text-orange{
        color: var(--steve-orange-main)!important;
    }
}
.sd-dark-theme .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bbbbbb36;
    border-color: $sd_input_text;
}

.p-divider-solid.p-divider-horizontal:before {
    border-top-style: solid!important;
}
.sd-layout .p-button {
    margin-right: 0.5rem;
}
.sd-layout .p-buttonset .sd-layout .p-button {
    margin-right: 0;
}
 .sd-layout .p-button i {
    line-height: 2.25rem;
}
 .sd-layout .p-button.google {
    background: linear-gradient(to left, var(--purple-600) 50%, var(--purple-700) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--purple-700);
}
 .sd-layout .p-button.google:hover {
    background-position: left bottom;
}
 .sd-layout .p-button.google i {
    background-color: var(--purple-700);
}
 .sd-layout .p-button.google:focus {
    box-shadow: 0 0 0 1px var(--purple-400);
}
.c-login-btn{
    margin-bottom: 1rem;
}

@media screen and (max-width: 960px) {
    .sd-layout {
        .c-login-frm{
            .p-button {
                margin-bottom: 0.5rem;
            }
            .p-button:not(.sd-layout .p-button-icon-only) {
                display: block;
            }
            .p-buttonset .sd-layout .p-button {
                margin-bottom: 0;
            }
        }
    }
}
.c-confirm-rpwd {
    max-width: 100vw;
}
.c-confirm-rpwd .p-dialog-content{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    span{
        margin: 1.5rem 0 1rem 0;
    }
    input {
        margin-left: 5px;
    }
}
