.c-pq-body{
    display: flex;
    flex-direction: column;
    width: 100vw;
    .c-pq-head{
        display: flex;
    }
    .c-pq{
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        min-height: 70vh;
        .c-pq-left{
            flex-grow: 1;
            background-color: #f2e3d1;
        }
        .c-pq-mid{
            flex-grow: 1;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .c-pq-right{
            flex-grow: 1;
            background-color: #f2e3d1;
        }
    }
}