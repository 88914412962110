.c-black-fs{
  width: 100vw;
  min-height: calc(100vh - 76px);
  background-color: #080808;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
  .c-sd-image{
    width: 292px;
    height: 170px;
    position: absolute;
    bottom: 0;
    left: -127px;
  }
  .c-bf-detail{

    .c-bf-text--orange{
      font-size: calc(20px + 4vw);
      margin: 2rem 2rem 0 2rem;
      color: #FF9E00;
      text-shadow: #696969 2px 2px;
    }
    .c-bf-underlined{
      text-decoration: underline;
    }
    .c-bf-prices{
      .sub-price{

      }
      .old-price{
        /*font-size: calc(16px + 1.5vw);*/
        text-decoration: line-through;
      }
      .text-red {
        color: #eb152e;
        font-size: calc(14px + 1.5vw);
      }
    }
  }
}

.giftcard-form {
    margin: auto;
    max-width: 90vw;
    width: 445px;
    padding-bottom: 2rem;
    box-sizing: border-box;
    font-family: sans-serif;
    .form-header {
      width: 100%;
      margin-bottom: 20px; /* Added margin between image and form elements */
    }
    .sub-cons{
      text-align: left;
      margin-left: 20px;
    }
    img {
      width: 100%;
      height: auto;
      display: block;
    }
    .c-steve-text{
      font-family: 'fanta-regular';
    }
    .p-button-orange{
      background-color: #F79A02;
      color: #000000;
      margin: 0!important;
      border: 1px solid #F79A02;
      font-family: 'fanta-regular';
      &.p-top-margin{ 
        margin-top: 1rem!important;
      }
      &:hover, &:active, &:focus{
        background: #F79A02!important;
        color: #000000!important;
        border-color: #F79A02!important;
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f59800, 0 1px 2px 0 black;
      }
    }
    label {
        display: block;
        margin-bottom: 5px;
        text-align: left;
        margin-left: 20px;
    }

    input,
    .p-button {
      width: calc(100% - 20px); /* Subtract padding for button width */
      margin-bottom: 10px; /* Added margin between elements */
    }

    .p-calendar {
      width: 100%;
      margin-bottom: 10px; /* Added margin between elements */
    }

    .p-button {
      margin-top: 10px;
    }

    @media (max-width: 767px) {
      max-width: 90vw;
    }
  }
  @media (max-width: 767px) {
    .c-black-fs {
      .c-sd-image{
        position: inherit;
        height: 150px;
        margin: auto;
        overflow: hidden;
        left: 0;
     }
    }
    .giftcard-form{
      padding-bottom: 0!important;
    }
  }