/* At the top level of your code */
@media screen and (min-width: 900px) {
  .user-info .podcast-details {
    min-width: 440px;
  }
}
.c-coupon-container {
  width: 100vw;
  max-width: 100vw;
  display: flex;
  margin: 0 auto;
  background-color: white;
  .c-coupon-ir {
    margin: 1rem auto;
  }
}
@media screen and (max-width: 960px) {
  .c-user-box{
    border-radius: 0px!important;
  }
}