$max-width: 1200px;
$gutter: 20px;

$footer_height: 150px;
$header_height: 76px;
$font-main: 'fanta-regular', Lora, "Times New Roman", serif;
$font-heading: "Roboto Mono", "Courier New", monospace;
$--steve-drk-prpl: #8B0061;
$--steve-lgt-prpl: #A60A8C;
$--steve-orange-secondary: #DE8D08;
$--steve-orange-main: #F59800;
$--steve-black-text: #000000;
$--steve-white-text: #ffffff;
$--hlusta-header-height: 95px;
$--steve-grey-bckgrnd: #313131;

:root{
  --steve-base:#efefef;
  --steve-blck: #0D0D0D;
  --steve-orng: #F28705;

  --steve-lgt-prpl: #A60A8C;
  --steve-drk-prpl: #8B0061;
  --steve-orange-secondary: #DE8D08;
  --steve-orange-main: #F59800;
  --steve-btn-black: #1A1A1A;
  --steve-black-base: #181D1B;
  --steve-black-text: #000000;
  --steve-white-text: #ffffff;
  --steve-grey-bckgrnd: #313131;
}
.p-toast-top-right {
  right: 0px!important;
  max-width: 100%!important;
}
.sd-color{
  color: $--steve-orange-main;
};
.sd-bg-color{
  background-color: $--steve-orange-main;
};
.p-button--purple{
  font-family: 'fanta-regular';
  background: $--steve-drk-prpl!important;
  border: 1px solid $--steve-drk-prpl!important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  &-normal{
    font-family: sans-serif;
  }
}
  body:has(.music-player-panel) #root{
    max-height: calc(100vh - 75px);
    position: absolute;
    width: 100%;
    overflow: auto;
  }
  
.sd-layout:not(.p-confirm):not(.sd-exclude):not(.p-button-danger){
  .c-sd-black{
    color: var(--steve-btn-black)!important;
  }
  .p-button.c-sd-black:focus{
    box-shadow: 0 0 0 1px #1a1a1a!important;
  }
  .p-button:focus(.sd-exclude){
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px $--steve-lgt-prpl, 0 1px 2px 0 black;
  }
  .p-button--purple:hover, .p-button:enabled:hover(.sd-exclude), .p-button:not(button):not(a):not(.p-disabled):hover(.sd-exclude){
    background: $--steve-drk-prpl;
    color: #ffffff;
    border-color: $--steve-drk-prpl;
  }
  .sd-text-orange{
    color: #F59800!important;
  }
  .c-fullscreen{
    width: 100%;
    min-height: calc(100vh-#{$header_height}-#{$footer_height});

  }
  .c-sd-orng-background{
    background: var(--steve-orange-main);
  }
}
