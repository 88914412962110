.notif-icon{
    color: #ffffff;
    padding: 0.5rem;
    border-radius: 1rem;
}
.p-dialog .p-dialog-content {
    padding: 0 1.5rem 0rem 1.5rem!important;
}
.c-notif-container{
    width: 400px;
    max-width: 100vw;
    font-family: 'markPro';
    .p-dialog-header-icons {
        padding-top: 0.5rem!important;
    }
    .text-red{
        color: red!important;
    };
}