.c-sd-aboutus-container{
    width: 100%;
    font-family: markPro;
    background: var(--steve-orange-main);
}
.c-sd-aboutus{
    max-width: 1080px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: #313131;
}
.c-sd-host-img img{
    object-fit: cover;
    border-radius: 50%;
    width: 200px;
    height: 200px;
}
.c-sd-host{
    display: flex;
    flex-wrap: wrap;
    flex-basis: 3;
    margin: 2rem;
    align-items: center;
    justify-content: center;
}
.c-sd-host.c-sd-host-right{
    flex-wrap: wrap-reverse;
}
.cd-sd-host-img{
    flex: 3;
}
.c-sd-host-info{
    flex: 2;
}
.c-sd-host-social{
    display: flex;
    margin: 1rem 1rem 0.5rem 1rem;
    justify-content: center;
}
.c-sd-host-social a{
    margin: 0 1rem;
}
.c-sd-host-txt{
    max-width: 30rem;
    margin: 2rem;
    color: #FFFFFF;
    p{
        color: #FFFFFF!important;
    }
}
.c-sd-host-left .c-sd-host-txt{
    text-align: left;
}
.c-sd-host-right .c-sd-host-txt{
    text-align: right;
}
a:link    {color: #FFFFFF;}
a:visited {color:#FFFFFF;}
a:hover   {color:#FFFFFF;}
a:active  {color:#FFFFFF;}