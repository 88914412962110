.p-datatable, .p-toggleable-content{
  max-width: 90vw!important;
  overflow: scroll;
}
.p-max40vw{
  max-width: 40vw;
}
.sd-dashboard{
  width: 100%;
  background-color: #f2f5ff;
  overflow: scroll;
  .sd-dashboard-ir{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 0 1rem;
    display: inline-block;
    text-align: center;
    overflow: scroll;
    .sd-dashboard-ir-left{
      /* width: 60%;*/
      display: inline-block;
      text-align: center;
    }

  }
}
.p-sidebar-right .p-sidebar.askur {
  background-color: white;
  color: black;
  max-width: 100vw;
  width: 800px;
  .sd-dashboard-ir-right{
    width: 100%;
    height: 100%;
  }
  .p-sidebar-content{
    max-height: calc(100vh - 150px);
  }
  .c-subscription-actions{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 1rem;
    background-color: grey;
  }
}
.sd-firebase-users{
  margin-top: 1rem;
  max-width: 95vw;
}
.p-dialog-mask{
  z-index: 4000!important;
}
.p-toast {
  z-index: 4001!important;
}
.p-dropdown-panel{
  z-index: 4002!important;
}
.c-sub-form {
  display: flex;
  justify-content: space-between;
}