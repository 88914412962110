/* At the top level of your code */
@media screen and (min-width: 900px) {
  .user-info .podcast-details {
    min-width: 440px;
  }
}
.c-user-details{
  width: 30rem;
  max-width: 100vw;
  display: flex;
  margin: 0 auto;
  .c-user-box{
    margin:auto;
    background: #313131; /*var(--surface-card);*/
    color: #FFFFFF;
    padding: 2rem;
    width: 100%;
    height: 100%;
    .p-divider .p-divider-content {
      /* background-color: #efefef; */
      background-color: #ffffff;
      color: var(--steve-black-base);
    }
    .c-user-info{
      font-family: sans-serif;

      .c-user-info-val{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        border-width: 0.5px;
        border-style: solid;
        border-color: grey;
        padding: 0.5rem;
        &.c-user-header{
          flex-direction: row;
          margin-top: 1rem;
          justify-content: flex-start;
          align-items: center;
          background-color: #f59800;
          color: #181d1b;
          border-radius: 0.5rem 0.5rem 0 0;
          .c-user-info-val-disp{
            font-size: 1.5rem;
            margin: 0!important;
            color: #f59800;
          }
          .c-user-info-val-val{
            margin: 0!important;
          }
        }
        .c-user-info-val-success{
          color: var(--green-400);
        }
        .sd-cancel {
          width: 100%;
          margin: 0;
          .p-button{
            margin: 0;
            background-color: transparent;
          }
          .p-button-label{
            text-align: start;
          }
        }
        .c-info-master-warn{
          text-align: left;
        }
        .c-user-info-val-disp{
          font-size: 0.8rem;
          margin: 0.5rem;
          color: #f59800;
        }
        .c-user-info-val-val{
          font-size: 1.2rem;
          margin: 0 0.5rem 0.5rem 0.5rem;
          max-width: 90%;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: left;
          .c-sub-btn {
            margin: auto;
          }
          &.c-user-info-val-warn{
            color: rgb(220, 53, 69);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .c-user-box{
    border-radius: 0px!important;
  }
}