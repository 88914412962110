Img{
    max-width: 100%;
}
.flex-container{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap-reverse;
  background-color: var(--steve-orange-main);
  flex-direction: row;
  align-items: center;
}
.image-container{
  margin: 2rem;
}
.image2-container{
  margin: 2rem 2rem 0 2rem;
}
.podcast-details{
  max-width: 490px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2rem;
}
.podcast-details p{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  font-family: markPro;
  /* or 167% */
  color: #1A1A1A;
  text-align: left;
}
.c-sd-title{
  font-family: 'fanta-regular';
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 90px;
  line-height: 90px;
  /* or 100% */
  color: #1A1A1A;
}
.c-sd-home-cover{
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.c-sd-collage-container{
  width: 100%;
  .c-sd-collage{
    background-color: #181D1B;
    width: 100%;
    min-height: 999px;
    display: flex;
    padding-top: 1rem;
  /*  .p-image.p-component{
      background: #313131;
    }*/
    .c-sd-background{
      .p-image-collage img{
        object-fit: cover;
      }
    }
    .c-sd-paintings{
      width: 1013px;
      display: flex;
      margin: auto;
      flex-direction: column;
      .c-sd-painting-flex{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        &.c-sd-flex-wrap{
          align-items: center;
          flex-wrap: wrap;
        }
        &.c-sd-flex-wrap-rev{
          align-items: start;
          flex-wrap: wrap-reverse;
        }
        .p-frame-img1 img{
          width: 484px;
        }
        .p-frame-img2 img{
          width: 464px;
        }
        .p-frame-img3 img{
          width: 484px;
        }
        .p-frame-img4 img{
          width: 396px;
        }
      }
      .p-frame-img{
        max-width: 95vw;
        margin: 1rem;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  #c_buy_sub {
      width: 100%;
      display: block;
  }
  .button-container{
    width: 100%;
  }
  .c-sd-painting-flex{
    justify-content: center!important;
  }
}