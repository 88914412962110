html, body {
  margin: 0;
  font-family: 'fanta-regular', 'Mark Pro Regular', markPro,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

@font-face {
    font-family: 'fanta-regular';
    src: local('CustomFont1Name'),
         url('./fonts/fanta_regular_by_pependejo-dbvx61f.ttf') format('woff'),
}
@font-face {
  font-family: 'Mark Pro Regular';
  src: local('CustomFont1Name1'),
       url('./fonts/MarkPro.otf') format('opentype'),
}
@font-face {
  font-family: markPro;
  src: url('./fonts/MarkPro.otf') format('opentype');
}
code {
  font-family: 'fanta-regular', 'Mark Pro Regular', sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
