.react-jinke-music-player-main{
    display: contents;
    font-family: sans-serif!important;
}
.react-jinke-music-player, .react-jinke-music-player-mobile{
    z-index: 990!important;
}
.react-jinke-music-player .music-player-controller {
    background-size: cover!important;
};
.react-jinke-music-player-main .music-player-panel .panel-content .img-rotate{
    background-size: cover!important;
}
.react-jinke-music-player-mobile-cover .cover {
    animation: none !important;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.react-jinke-music-player-main .music-player-panel .panel-content .rc-slider-handle:active {
    box-shadow: 0 0 2px #8b0061;
}
.react-jinke-music-player-main .music-player-panel .panel-content .rc-slider-handle, .react-jinke-music-player-main .music-player-panel .panel-content .rc-slider-track {
    background-color: #8b0061;
}
.c-skip-btn{
    background: transparent;
    border: none;
    color: white;
}
.react-jinke-music-player-mobile-operation .items .item:not(:has(.audio-download)) svg {
    display: none;
}
.c-sd-skip-btns{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-right: 1rem;
}
.c-sd-skip-btns i:after {
    content: '15';
    font-size: 10px;
}
.react-jinke-music-player-main.light-theme .c-skip-btn {
    color: #31c27c;
}
span[title^='Previous track'],
span[title^='Next track'] {
    display: none!important;
}
