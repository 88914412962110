@import '../../config.scss';
footer {
    width: 100%;
    /* height: #{$footer_height};*/
    bottom: 0;
    background-color: #0d0d0dd9;
    color: #F2F2F2;
    font-family: markPro;
    .footer-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        padding: 5px;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        .social-container{
            display: flex;
            flex-direction: row;
            height: 135px;
            div{
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-content: stretch;
                align-items: center;
                justify-content: space-evenly;
                margin-right: 1.2rem;
            }
        }
        .social-container a{
            margin: 10px;
        }
    }
    .c-footer-info{
        margin: 1rem;
        ul{
            padding-left: 5px;
            li{
                list-style: none !important;
                p{
                    text-align: left;
                }
                &:hover{
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
}


.p-sidebar{
    .p-sidebar-content{
        display: flex;
        align-content: flex-end;
        align-items: center;
        justify-content: space-evenly;
        padding: 0;
    }

}
.p-sidebar-mask{
    z-index: 3500!important;
}
.sd-text-orange{
    color: #F59800!important;
}
.cookie-banner{
    z-index: 999999999;
}
@media screen and (max-width: 660px) {
}
@media screen and (max-width: 960px) {
    footer{
        height: auto!important;
    }
    #cookies_btn{
        margin-left: 0!important;
        padding-left: 0!important;
        border: none!important;
        box-shadow: none!important;
    }
    .p-sidebar{
        justify-content: center!important;
        .p-sidebar-content{
            display: flex;
            align-content: space-evenly;
            align-items: flex-start;
            justify-content: space-evenly;
            padding: 0;
            flex-direction: column;
        }
        .align-items-center{
            text-overflow: ellipsis;
            display: -webkit-box!important;
            max-width: 270px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

/* ButtonDemo.css */
