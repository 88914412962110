/* DialogDemo.css */

.c-privacy-wndw .p-button {
    margin: 0 .5rem 0 0;
    min-width: 10rem;
}

.c-privacy-wndw p {
    margin: 0;
    line-height: 1.5;
}

.c-privacy-wndw .p-dialog .p-button {
    min-width: 6rem;
}
.c-privacy-wndw {
    width: 50vw;
}
.c-legal-btns{
    margin-top: 1rem;
}
.c-wh-container{
    display: flex;
    .c-wh-message{
        margin-left: 0.5rem;
    }
}
@media screen and (max-width: 960px) {
    .c-privacy-wndw {
        width: 100vw;
    }
}
@media screen and (max-width: 640px) {
    .c-privacy-wndw {
        width: 100vw;
        min-width: 0;
    }
}