.sd-google-login{
    display: block;
}
.form-demo {
    margin: auto;
}
.card .p-button--purple{
    margin-bottom: 1rem;
}
.form-demo .card {
    min-width: 450px;
}
.form-demo .card form {
    margin-top: 2rem;
}
.form-demo .card .field {
    margin-bottom: 1.5rem;
}
.form-demo .p-custom-link-btn {
    max-width: 110px;
}
.p-linklike {
    color: var(--primary-color) !important;
    text-decoration: underline;
}
.p-linklike:hover{
    cursor: pointer;
}
.c-login-frm .loading-spinner{
    position:fixed;
    left: calc(50% - 50px);
    top: 35%;
    z-index: 99999;
}
.c-login-frm .p-blockui-container{
    width: 100%;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.29);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}
.c-sd-login-head{
    display: flex;
    flex-direction: column;
}
.sd-skilmalar-text{
    color: var(--steve-white-text);
    margin-top: 16px;
}
@media screen and (max-width: 960px) {
    .form-demo .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .form-demo .card {
        width: 100%;
        min-width: 0;
    }
}