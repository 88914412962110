/* At the top level of your code */
@media screen and (min-width: 900px) {
  .user-info .podcast-details {
    min-width: 440px;
  }
}
.c-video-container {
  width: 95vw;
  max-width: 800px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  color: white;
  .c-video-playlist {
    .c-title{
      text-align: start;
      margin: 1rem 1rem 0 1rem;
      font-size: larger;
    }
    .c-episode-about{
      width: 100%;
      text-align: start;
      font-family: sans-serif;
      color: grey;
      margin-top: 0.5rem;
    }
    .c-episode-item {
      display: flex;
    /* flex-wrap: nowrap; */
      flex-direction: column;
      .c-episode-info{
        display: flex;
    /* flex-direction: column; */
    /* align-items: stretch; */
    /* margin-left: 1rem; */
        justify-content: space-between;
        .c-episode-img-text{
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
        }
        .c-episode-playbtn{
          display: flex;
          align-content: space-around;
          flex-wrap: wrap;
        }
        .c-episode-text{
          display: flex;
          align-content: space-between;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          flex-wrap: nowrap;
          margin-left: 1rem;
        }
        .c-episode-description{
          color: grey;
          font-family: sans-serif;
        }
      }
    }
  }
  ul {
    padding: 0.5rem;
    margin: 0;
    li {
      list-style: none;
      padding: 1rem;
      border-radius: 1rem;
      margin-top: 1rem;
      &:hover{
        background-color: #80808063;
      }
      .p-component-overlay-enter {
        animation: none!important;
      }
      .p-component-overlay {
        background-color: transparent!important;
        transition-duration: 1s!important;
      }
      img {
        width: 98px;
        height: 98px;
        object-fit: cover;
        border-radius: 1rem;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .c-user-box{
    border-radius: 0px!important;
  }
}