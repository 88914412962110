.c-blog-container{

    background: #313131;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100vw;
    margin: 0 auto;
    font-family: markPro;
    padding-top: 0px;
    .c-blog-playlist{
        width: 100%;
        background-color: #313131 !important;
        display: flex;
        justify-content: center;
    }
    .c-blog-info-container{
        min-height: 50vh;
    }
    .c-blog-controls{
        display: flex;
    }
    .c-blog-header{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin-bottom: 1rem;*/
        .c-blog-img-container{
            width: 100vw;
            background-color: #f59800;
            max-width: 100%;
            .c-blog-skeleton{
                width: 512px!important;
                height: 512px!important;
                margin: 1rem auto;
                max-width: calc(100% - 2rem);
                max-height: calc(100vw - 2rem);
            }
            img{
                width: 512px;
                margin: 1rem auto;
                max-width: calc(100% - 2rem);
            }
        }
    }
    .c-blog-info-container{
        width: 512px;
        max-width: 100vw;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .c-blog-width{
            /* max-width: 512px;
            margin: auto;*/
        }
        .c-blog-buttons{
            margin: 1rem;
        }
        .c-blog-description {
            flex-grow: 4;
            p{
                font-size: 16px;
                line-height: 1.6;
                font-style: normal;
                font-weight: normal;
                text-decoration: none;
                text-align: left;
                padding: 1.5rem;
            }
        }
        .c-episode-controls{
            display: flex;
            width: 100%;
        }
        .c-blog-container-header{
            width: 100%;
            text-align: left;
            font-weight: 600;
            padding: 1rem;
            .c-blog-header-text{
                font-size: 2rem;
            }
            .c-blog-header-epinfo{
                font-size: 1rem;
            }
        }
    }

}
$--rm-audio-player-interface-container: transparent!important;
.rm-audio-player-provider{
    background-color: transparent!important;
    max-width: 512px;
    margin: auto;
    .play-button{
        width: 60px!important;
    }
}
.interface-grid{
    background-color: transparent!important;
}