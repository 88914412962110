@import '../../config.scss';
@font-face {
    font-family: 'fanta-regular';
    src: local('CustomFont1Name'),
         url('../../fonts/fanta_regular_by_pependejo-dbvx61f.ttf') format('woff'),
}
.container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
header,
footer {
}
/* Hide scrollbar for Chrome, Safari and Opera */
main::-webkit-scrollbar {
  display: none;
}
  /* Hide scrollbar for IE, Edge and Firefox */
main {
  height: 100%;
}
.sd-font {
    font-family: 'fanta-regular', sans-serif !important;
}
.sd-layout{
    min-height: calc(100vh - #{$footer_height} - #{$header_height});
    display: flex;
    flex-direction: column;
    background-color: var(--steve-black-base);
    .sd-banner{
      background-color: #313131;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      color: white;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.5rem;
    }
}
