.c-data-container{
  background-color: white;
  font-family: markPro;
  .c-filters{
    flex: 2;
    order: 1;
    background-color: white;
    z-index: 999;
  }
  .c-data{
    flex: 10;
    order: 2;
    width: 800px;
    max-width: 90vw;
  } 
}
@media (max-width: 600px) {
  .c-filters   {
    max-width: 100%; /* Maximum width for small screens, 100% of the screen */
  }
}