.c-retry-payment {
    width: 400px;
    display: flex;
    font-family: markPro;
    max-width: 90vw;
    img{
        width: 120px;
        margin: auto;
    }
}
.c-stadfesta {
    z-index: 9999!important;
}
.c-master-err-dialog{
    width: 95vw;
    max-width: 400px;
}
