@import '../../config.scss';
.c-episodes-loadmore {
    padding: 1rem;
    button{
        color: white!important;
        &:hover{
            background: #313131!important;
            color: #6c757d!important;
        };
    }
}
.c-sd-header {
    font-family: markPro;
}
.playlist-item {
    border: 1px solid var(--surface-d);
    border-radius: 1rem;
    margin-top: 1rem;
    width: 480px;
    max-width: 95vw;
    min-width: 240px;
    transition: 0.3s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .p-button:focus {
        box-shadow: none!important;
    }
    .p-chip{
        &:hover{
            cursor: pointer;
        }
    }
    .c-add-track-btn{
        position: absolute;
        right: 0;
    }
    .p-card-header{
        border-radius: 1rem 1rem 0 0;
        .p-skeleton{
            border-radius: 1rem 1rem 0 0;
        }
    }
}
.playlist-item:hover, .c-active-episode{
    /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
    box-shadow: rgba(245, 152, 0, 1) 0px 3px 11px 1px;
}
.episodes-container ul {
    list-style: none;
}
.playlist-item div{
    overflow: hidden;
}
.progress {
    width: 1px;
    height: 3px;
    background: #006B5A;
    transition: width .1s linear;
}
.p-card .p-card-body{
    padding: 0.5rem 1rem 0.5rem 1rem;
}
.p-card .p-card-content {
    padding: 0;
    height: 13em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.p-card-header img {
    /*width: 350px;*/
    height: auto!important;
    object-fit: contain!important;
    border-radius: 1rem 1rem 0 0;
}
.p-card .p-card-footer{
    padding: 1rem 0 0 0;
}

.c-episode-icons {
    display: flex;
    width: 100%;
}
.c-episode-icons-container {
    align-self: center;
    margin-right: 1rem;
}
.c-episode-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
/* Hide scrollbar for IE, Edge and Firefox */
.c-episode-info-container::-webkit-scrollbar-track {
    background-color: transparent;
}
.c-episode-info-container::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
    &:hover{
        background-color: #a8bbbf;
    }
}
.c-episode-info-container p{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: start;
}
    /* Hide scrollbar for IE, Edge and Firefox */
    p::-webkit-scrollbar-track {
            /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
            border-radius: 10px;
            background-color: transparent;
    }
    p::-webkit-scrollbar-thumb {
            border-radius: 10px;
            /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
            background-color: grey;
    }
    p::-webkit-scrollbar{
        width: 3px;
        background-color: transparent;
    }
.c-episode-info-container .c-episode-tags a{
    color: #0D0D0D;
    text-decoration: none;
}
.c-episode-info-container .c-episode-tags{
    margin-top: 4px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
}
p .p-skeleton{
    margin-top: 0.5em;
}
.c-episode-info-container .c-episode-tags .pi-tag{
    margin-right: 0.5rem;
}
.p-card .p-card-title{
    box-shadow: none;
    margin: 0;
}
.c-episode-icons-container span{
    padding-right: 0.25rem;
}
.c-playlist-item{
    .p-blockui-container{
        margin-top: 0;
        top: -1rem;
    }
    .p-blockui.p-component-overlay {
        border-radius: 1rem;
        z-index: 985 !important;
    }
}

.p-chip:has(.c-episode-tag-active){
    background-color: var(--steve-orange-main);
}
/* @media screen and (max-width: 820px) {
    .c-hlusta{
        .episodes-container{
            overflow: auto;
            scroll-snap-type: y mandatory;
            max-height: calc(100vh - #{$header_height});
        }
        .c-playlist-item{
            scroll-snap-align: start;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .playlist-item{
            width: 21em!important;
        }
        .p-blockui.p-component-overlay {
            border-radius: 1rem;
            margin-top: 1rem;
            height: calc(100% - 1rem);
            z-index: 985 !important;
        }
    }
}
*/
@media screen and (min-width: 821px) {
}