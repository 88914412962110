/*.c-cc-form{
    border-style: solid;
    border-width: 1px;
    padding: 1rem;
    border-radius: 1rem;
    border-color: #efefef;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}*/
.c-cc-input{
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
    width: 100%;
}
.c-cc-input:hover{
    border-color: #3B82F6;
}
.c-cc-input:focus{
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
    border-color: #3B82F6;
}